import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Layout from '@components/layout/Main'
import useT from '@hooks/useTranslation'

export default function Page404 () {
    const router = useRouter()

    useEffect(() => {
        router.push('/samochody-dostawcze')
    }, [])

    return (
        <Layout>
            <section className="container min-height" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div style={{textAlign:'center'}}><i style={{color:'var(--teal)'}}>¯\_(ツ)_/¯</i><h1>{useT('Błąd')} 404</h1><p>{useT('Nie znaleziono strony')}.</p></div>
            </section>
        </Layout>
    )
}